<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-btn
          icon
          exact
          to="/colaborador/midias-sociais/calendario-midias-sociais"
          color="primary"
          ><v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-divider vertical class="mx-2"></v-divider>

        <div class="d-flex justify-center align-center">
          <v-avatar size="30" v-if="calendario.cliente_logo" class="ml-2">
            <v-img :src="calendario.cliente_logo"> </v-img>
          </v-avatar>

          <div class="ml-2 ">{{ calendario.cliente }}</div>
        </div>
        <v-spacer></v-spacer>

        <v-card class="text-body-1 px-3 py-1" flat color="grey lighten-4">
          <span v-if="calendario.status === -1">CANCELADO</span>
          <span v-else-if="calendario.status === 1">ATIVO</span>
          <span v-else-if="calendario.status === 2">CONCLUIDO COLABORADOR</span>
          <span v-else-if="calendario.status === 3">CONCLUIDO GESTOR</span>
          <span v-else-if="calendario.status === 4">CONCLUIDO CLIENTE</span>
        </v-card>
      </v-card-title>
      <v-divider></v-divider>
      <v-tabs v-model="tab">
        <v-tab>Postagens</v-tab>
        <v-tab v-if="postagensCalendarios.length">Calendario</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text>
            <ListaPostagemCalendarioMidias
              :postagensCalendarios="postagensCalendarios"
              :calendario="calendario"
              @fetch-postagem="getPostagens"
              v-if="!loading"
            />
          </v-card-text>
        </v-tab-item>
        <v-tab-item v-if="postagensCalendarios.length">
          <v-card-text>
            <CalendariosPostagens
              :calendario="calendario"
              @fetch-postagem="getPostagens"
              :key="calendarioKey"
            />
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import { fetchMidiaSocialCalendario } from "@/api/colaborador/midias_sociais_calendario.js";
import { fetchCalendariosPostagens } from "@/api/colaborador/midias_sociais_calendario_postagem.js";

export default {
  name: "CalendarioMidiaSociais",

  components: {
    ListaPostagemCalendarioMidias: () =>
      import("./components/ListaPostagemCalendarioMidias.vue"),
    CalendariosPostagens: () => import("./components/CalendariosPostagens.vue"),
  },

  data() {
    return {
      tab: 0,
      loading: true,
      calendario: {},
      postagensCalendarios: [],
      calendarioKey: 0,
    };
  },

  computed: {
    calendario_id() {
      return this.$route.params.calendarioId;
    },
  },

  methods: {
    getPostagens() {
      return fetchCalendariosPostagens(
        `?calendario=${this.calendario_id}`
      ).then((r) => {
        this.postagensCalendarios = r;
        this.calendarioKey += 1;
      });
    },
  },

  async created() {
    this.loading = true;
    const response_calendario = await fetchMidiaSocialCalendario(
      this.calendario_id
    );
    this.calendario = response_calendario;

    await this.getPostagens();

    this.loading = false;
  },
};
</script>

<style lang="scss"></style>
